import { Injectable } from '@angular/core';
import { HttpService } from './system/http.service';
import { PaymentApi } from './enums';
import { MobileService } from './system/mobile.service';
import { ConfirmService } from '../shared/confirm/confirm.service';
import { UserService } from './data-services/user.service';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(
    private httpService: HttpService,
    private mobileService: MobileService,
    private confirmService: ConfirmService,
    private userService: UserService,
  ) {}

  async createSessionPremiumMonths(months) {
    const response = await this.httpService.postDataToService(PaymentApi.CreateCheckoutSession, { months: months });
    if (response.sessionUrl) {
      window.location.href = response['sessionUrl'];
    } else {
      console.error('POST no puede contactar el sistema de pagos error', response);
    }
  }

  async updateUserSubscriptionIfPaid(sessionId: string) {
    const response = await this.httpService.getDataFromService(`${PaymentApi.ApplySusbcription}/${sessionId}`);
    return response;
  }

  public async payStripe(months: number) {
    // TODO para dejar limpio este servicio y ser reutilizable me conviene crear un servicio con    ConfirmService MobileService, UserService
    debugger;
    if (this.mobileService.isNativeMobile()) {
      this.confirmService.openConfirm(
        'Por ahora la compra solo se puede realizar en la página web, por favor ve a polilan.com para adquirir tu plan',
        'Pronto, agregaremos la función para comprar desde AppStore/PlayStore con Google Pay y Apple Pay',
        'Ok, iré a polilan.com',
      );
      return;
    }
    if (this.userService.isPremium()) {
      alert('Ya tienes una suscripción Premium activa');
    } else {
      try {
        await this.createSessionPremiumMonths(months);
      } finally {
        this.userService.getUser$();
      }
    }
  }
}
