import { InjectionToken, Type } from '@angular/core';
import { Provider } from '@angular/core';
import {
  ConversationPromptSettings,
  ConversationDTO,
  ConversationUserSettings,
  IConversationCard,
  ModelName,
  FiltersConfig,
} from './conversation.interface';

export const CONVERSATION_AI_TOKEN = new InjectionToken<ConversationAIAbstractService>('Conversation Ai Service');

export const TOAST_ALERTS_TOKEN = new InjectionToken<ToastAlertsAbstractService>('Toast alerts Service');

export interface ToastData {
  title: string;
  subtitle: string;
}
export abstract class ToastAlertsAbstractService {
  abstract success(data: ToastData): void;
  abstract info(data: ToastData): void;
  abstract warn(data: ToastData): void;
  abstract error(data: ToastData): void;
}

export function provideToastAlert(serviceImplementation: Type<ToastAlertsAbstractService>): Provider[] {
  return [
    {
      provide: TOAST_ALERTS_TOKEN,
      useExisting: serviceImplementation,
    },
  ];
}

// abstract-my-service.ts
export abstract class ConversationAIAbstractService {
  // TODO define the type of return value
  abstract callChatCompletion(conversation: ConversationPromptSettings): Promise<any>;
  // Get conversation card by id
  abstract findConversationCard(id: string): Promise<IConversationCard>;
  // Get all conversation cards
  abstract getAllConversationCards(): Promise<IConversationCard[]>;
  // This is the one to use to filter cards. TODO! change the returning type to be a paginator
  abstract getConversationCards(paginator: FiltersConfig): Promise<IConversationCard[]>;

  abstract saveConversationCard(conversation: IConversationCard): Promise<IConversationCard>;

  abstract deleteConversationCard(id: string): Promise<IConversationCard>;

  abstract getTextAudioFile(tts: any): Promise<any>;

  abstract getConversationUserChatSettings(): Promise<ConversationUserSettings>;

  abstract getListModels(provider: string): Promise<ModelName[]>;

  abstract translateConversation(currentLang: string, targetLang: string, id: string): Promise<ConversationUserSettings>;

  // abstract getConversationPromptSettings(): Promise<ConversationPromptSettings>;

  abstract saveConversationUserChatSettings(conversation: ConversationUserSettings): Promise<ConversationUserSettings>;
}

// my-service.provider.ts
export function provideChatAIService(serviceImplementation: Type<ConversationAIAbstractService>): Provider[] {
  return [
    {
      provide: CONVERSATION_AI_TOKEN,
      useExisting: serviceImplementation,
    },
  ];
}
