import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  version: '0.1.0',
  envName: 'QA',
  production: false, // cambiar a true para enviar notificaciones
  backendPythonUrl: 'https://python-server-484991861457.us-central1.run.app',
  backendNodeUrl: 'https://node-server-484991861457.us-central1.run.app',
  clientId: '484991861457-ptsoclinglgnia7umfco5k6cbfnis32i.apps.googleusercontent.com',

  mobile: {
    appleAppId: 'com.polilan.app.qa',
    appleRedirectURI: 'https://appingles-qa.firebaseapp.com/__/auth/handler',
    androidClientId: '484991861457-chhvmiqtjq22je1nr9m13ouljrdeuhpi.apps.googleusercontent.com', // No la uso
    iosClientId: '484991861457-o3j8n8fv21huerkd0pj8r719jssrtbnh.apps.googleusercontent.com',
  },
  // base de datos en dllanguages.
  firebase: {
    apiKey: 'AIzaSyBahj2VQDGPnpjIKv38YEe8FAyn7bcaloU',
    authDomain: 'appingles-qa.firebaseapp.com',
    projectId: 'appingles-qa',
    storageBucket: 'appingles-qa.appspot.com',
    messagingSenderId: '484991861457',
    appId: '1:484991861457:web:bfbc6360b6e81c974f7bf0',
  },
  logLevel: NgxLoggerLevel.TRACE,
  // googleAnalytics: "G-DNL54VQM58",
  gtm: 'GTM-N9N3C37',
};
